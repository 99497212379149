<template>
	<div class="banner" ref="nav">
		<div class="carousel"  @mouseenter="handleMouseenter" @mouseleave="handleMouseleave">
			 <swiper
				class="swiper"
				:options="swiperOption"
				@slideChange="onSlideChange"
				ref="mySwiper"
                @click="navToPcLink"
			>
				<swiper-slide v-for="(item, index) in pcAdList" :key="index">
					<img
						:src="item.imgPathPc"
						alt=""
						class="img-pc"
					/>
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<div class="info f-y-c-s">
				<div class="index f-x-s-c">
					<span class="num">{{ formartLen(currentIndex + 1) }} </span>
					<span class="line"></span>
					<span class="num">{{ formartLen(pcAdList.length) }}</span>
				</div>

				<div class="content  f-x-b-c">
					<span class="title ">{{pcAdList[currentIndex] && pcAdList[currentIndex].title}}</span>
					<i @click="nextBanner('mySwiper')" class="right el-icon-right"></i>
				</div>
			</div>
		</div>
        <div class="carousel-mob">
			<swiper
				class="swiper"
				:options="swiperOptionMob"
				@slideChange="onSlideMobChange"
				ref="mySwiperMob"
                @click="navToMobLink"
			>
				<swiper-slide v-for="(item, index) in mobAdList" :key="index">
                    <img
						
						:src="item.imgPathPc"
						alt=""
						class="img-mob"
					/>
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<div class="info f-y-c-s">
				<div class="index f-x-s-c">
					<span class="num">{{ formartLen(currentMobIndex + 1) }} </span>
					<span class="line"></span>
					<span class="num">{{ formartLen(mobAdList.length) }}</span>
				</div>

				<div class="content  f-x-b-c">
					<span class="title ">{{mobAdList[currentMobIndex] && mobAdList[currentMobIndex].title}}</span>
					<i @click="nextBanner('mySwiperMob')" class="right el-icon-right"></i>
				</div>
			</div>
		</div>
		<div class="section3 f-y-c-s">
			<span class="spn">快速获取装修方案！</span>
			<div class="mod3">
                <el-form
                    :inline="true" 
                    ref="ruleFormRef"
                    :model="visitorInfo"
                    :rules="rules"
                    class="cus-form"
                >
                    <el-form-item  >
                        <div class="city-box">
                            <el-cascader
                                class="input city"
                                placeholder="您所在的城市"
                                size="default"
                                :options="cityStoreList"
                                v-model="city"
                            >
                            </el-cascader>
                            <img
                                slot="prefix"
                                class="city-img"
                                src="../image/index/index_ban1.png"
                                alt=""
                            />
                        </div>
                    </el-form-item>
                    <el-form-item  prop="houseArea">
                        <el-input
                            class="input"
                            placeholder="您家房屋面积"
                            inputmode="numeric"
                            v-model="visitorInfo.houseArea"
                        >
                            <img
                                slot="prefix"
                                class="pic01"
                                src="../image/index/index_ban4.png"
                                alt=""
                            />
                            <span slot="suffix" class="m2">m²</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item  prop="name">
                        <el-input
                            class="input"
                            placeholder="您的称呼"
                            v-model="visitorInfo.name"
                        >
                            <img
                                slot="prefix"
                                class="pic01"
                                src="../image/index/index_ban2.png"
                                alt=""
                            />
                        </el-input>
                    </el-form-item>
                    <el-form-item  prop="mobile">
                        <el-input
                            class="input"
                            placeholder="您联系电话"
                            v-model="visitorInfo.mobile"
                            inputmode="numeric"
                        >
                            <img
                                slot="prefix"
                                class="pic01"
                                src="../image/index/index_ban3.png"
                                alt=""
                            />
                        </el-input>
                    </el-form-item>
                    <el-form-item class="sub-form"  >
                        <div type="text" class="btn f-x-c-c" @click="submitForm">
                            立即免费获取
                        </div>
                    </el-form-item>
                </el-form>

				
                <!-- <div class="city-box">
                    <el-cascader
                        class="input city"
                        placeholder="您所在的城市"
                        size="default"
                        :options="cityStoreList"
                        v-model="city"
                    >
                    </el-cascader>
                    <img
                        slot="prefix"
                        class="city-img"
                        src="../image/index/index_ban1.png"
                        alt=""
                    />
                </div>
				<el-input
					class="input"
					placeholder="您家房屋面积"
                    inputmode="numeric"
					v-model="visitorInfo.houseArea"
				>
					<img
						slot="prefix"
						class="pic01"
						src="../image/index/index_ban4.png"
						alt=""
					/>
					<span slot="suffix" class="m2">m²</span>
				</el-input>
				<el-input
					class="input"
					placeholder="您的称呼"
					v-model="visitorInfo.name"
				>
					<img
						slot="prefix"
						class="pic01"
						src="../image/index/index_ban2.png"
						alt=""
					/>
				</el-input>
				<el-input
					class="input"
					placeholder="您联系电话"
					v-model="visitorInfo.mobile"
                    inputmode="numeric"
				>
					<img
						slot="prefix"
						class="pic01"
						src="../image/index/index_ban3.png"
						alt=""
					/>
				</el-input>
				<div type="text" class="btn f-x-c-c" @click="submit">
					立即免费获取
				</div> -->
			</div>
		</div>
        <MessageForm ref="refMessage" :list="errList"></MessageForm>
	</div>
</template>

<script>
import { postmobile } from '@/api/views/name.js'
import { getadData } from '@/api/advertising/advertising.js'
import {useCityInfo} from '@/utils/frequent.js'
import { mapState } from 'vuex'

export default {
	props: [
		'activityCode',
		'planCode',
		'mediaSource',
		'adsPlanName',
		'mediaSourceName',
	],
	components: {},
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback(new Error('请输入正确的电话号码'));
            }else{
                callback();
            }
        };
		return {
            errList:[],
            mobAdList:[],
            pcAdList:[],
			currentMobIndex: 0,
			currentIndex: 0,
			swiperOption: {
                initialSlide :0,
                autoplay:true,
                loop:true,
                slidesPerView : 'auto',
                loopedSlides:7,
				pagination: {
					el: '.carousel .swiper-pagination',
					// type: 'bullets',
					//type: 'fraction',
					//type : 'progressbar',
					type: 'custom',
					renderCustom: function (swiper, current, total) {
						var paginationHtml = ''
						for (var i = 0; i < total; i++) {
							// 判断是不是激活焦点，是的话添加active类，不是就只添加基本样式类
							if (i === current - 1) {
								paginationHtml +=
									'<div class="swiper-pagination-customs swiper-pagination-customs-activ"></div>'
							} else {
								paginationHtml +=
									'<div class="swiper-pagination-customs"></div>'
							}
						}
						return paginationHtml
					},
				},
			},
            swiperOptionMob: {
                initialSlide :0,
                autoplay:true,
                loop:true,
                slidesPerView : 'auto',
                loopedSlides:7,
				pagination: {
					el: ' .carousel-mob .swiper-pagination',
					// type: 'bullets',
					//type: 'fraction',
					//type : 'progressbar',
					type: 'custom',
					renderCustom: function (swiper, current, total) {
						var paginationHtml = ''
						for (var i = 0; i < total; i++) {
							// 判断是不是激活焦点，是的话添加active类，不是就只添加基本样式类
							if (i === current - 1) {
								paginationHtml +=
									'<div class="swiper-pagination-customs swiper-pagination-customs-activ"></div>'
							} else {
								paginationHtml +=
									'<div class="swiper-pagination-customs"></div>'
							}
						}
						return paginationHtml
					},
				},
			},
			records: [],
			city:[],
            // provinceAndCityData,
			cityStoreList:[],
			visitorInfo: {
				houseArea: '',
				mobile: '',
				name: '',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: 'XSdw', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
                salesRoomNo:''
			},
            rules:{
                houseArea: [
                    { required: true, message: ' ', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            timer:''
		}
	},
	async mounted() {
        this.getMobAdList()
        this.getPcAdList()


        this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
		this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
		this.visitorInfo.reqUrl = window.location.href

        this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
        this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
        this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
        this.visitorInfo.projectName =  this.$Cookie.get('projectName')
        this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
		// const timer = setInterval(() => {
		// 	if (this.planCode) {
		// 		clearInterval(timer)
		// 		this.visitorInfo.activityCode = this.activityCode
		// 		this.visitorInfo.projectCode = this.planCode
		// 		this.visitorInfo.mediaCode = this.mediaSource
		// 		this.visitorInfo.projectName = this.adsPlanName
		// 		this.visitorInfo.mediaName = this.mediaSourceName
		// 	}
		// }, 50)
        this.cityStoreList = await useCityInfo()
        if(this.curCityInfo.value!='00') this.setCity()
	},
	methods: {
        handleMouseenter(){
            this.$refs.mySwiper.$swiper.autoplay.stop();
        },
        handleMouseleave(){
            this.$refs.mySwiper.$swiper.autoplay.start();
        },
        getMobAdList(){
            const params = {
                current:1,
                size:5,
                advertisementSpace:'index',
                isMobile:1
            }
            getadData(params).then((res) => {
                if (res.code == 0) {
                    this.mobAdList  = res.data.records
                } 
            })
        },
        getPcAdList(){
            const params = {
                current:1,
                size:5,
                advertisementSpace:'index',
                isMobile:0
            }
            getadData(params).then((res) => {
                if (res.code == 0) {
                    this.pcAdList  = res.data.records
                } 
            })
        },
		nextBanner(data) {
			this.$refs[data].$swiper.slideNext()
		},
		onSlideChange() {
            this.currentIndex = this.$refs.mySwiper.$swiper.realIndex
		},
        onSlideMobChange(){
            this.currentMobIndex = this.$refs['mySwiperMob'].$swiper.realIndex
        },
		formartLen(num) {
			if (typeof num == 'number') {
				if (num < 10) return '0' + num
				else return num
			}
		},
        navToPcLink() {
            const index = this.$refs.mySwiper.$swiper.realIndex 
            const clickedItem = this.pcAdList[index]
            if (clickedItem) {
                // 处理点击事件
                // window.open('/#/ad/active'+clickedItem.url,clickedItem.target)
                window.open(clickedItem.url,clickedItem.target)
            }
		},
		navToMobLink() {
            const index = this.$refs['mySwiperMob'].$swiper.realIndex 
            console.log("index",index);
            const clickedItem = this.mobAdList[index]
            if (clickedItem) {
                // 处理点击事件
                // window.open('/#/ad/active-mob'+clickedItem.url,clickedItem.target)
                window.open(clickedItem.url,clickedItem.target)
            }
		},
		async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    if(valid){
                        if(this.city.length){
                            this.visitorInfo.cityCode = this.city[0]
                            this.visitorInfo.salesRoomNo = this.city[1]
                        }
                        const res = await postmobile(this.visitorInfo)
                        this.$refs.refMessage.showDialog('sucess')
                        this.city=[]
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
	},
    beforeDestroy(){
        clearTimeout(this.timer)
    }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 899px) {
	.banner {
        margin-top: 43px;
		position: relative;
		display: flex;
		justify-content: center;
		flex-flow: column;
		width: 100%;

		display: flex;
		align-items: center;
        .carousel{
            display: none;
        }
		.carousel-mob {
			width: 100%;
			height: 350px;
			position: relative;
            .swiper-container {
                height: 320px;
                z-index: initial;
            }
			.swiper {
				height: 100%;

                .img-mob {
                    width: 100%;
			        height: 320px;
                    display: block;
                    cursor: pointer;
                    object-fit: cover;
                }
                .img-pc{
                    display: none;
                }

                /deep/.el-carousel__container {
                    height: 320px;
                }

				
				/deep/ .swiper-wrapper {
					height: 320px;
				}
				/deep/ .swiper-pagination {
                    z-index: 9;
					display: flex;
					align-content: center;
					justify-content: start;
					box-sizing: border-box;
					width: 280px;
					margin-left: 95px;
					padding-left: 13px;
                    bottom: 30px;
					.swiper-pagination-customs {
						width: 50px;
						height: 1px;
						background: #dddddd;
						margin-right: 10px;
					}
					.swiper-pagination-customs-activ {
						width: 50px;
						height: 1px;
						background: #1b1d22;
					}
				}
			}
			.info {
				position: absolute;
				right: 0;
				bottom: 29px;
				width: 280px;
				height: 65px;
                background: rgba(255, 255, 255, 0.95);
				padding: 13px 23px 13px 13px;
				z-index: 5;
				box-sizing: border-box;
				.index {
					.num {
						font-family: DIN;
						font-weight: 300;
						font-size: 14px;
						color: #1b1d22;
					}
					.line {
						font-family: DIN;
						font-size: 12px;
						color: #ababab;

                        width: 1px;
                        background: #000;
                        height: 12px;
                        margin: -2px 8px 0 8px;
                        transform: rotate(12deg);
					}
				}
				.content {
                    width: 100%;
                    margin-top: 2px;
					.title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
						height: 20px;
						
						font-weight: 400;
						font-size: 20px;
						color: #1b1d22;
						line-height: 20px;
                        margin-right: 10px;
					}
					.right {
						font-size: 24px;
						// width: 27px;
						// height: 18px;
						color: #753cbe;
					}
				}
			}
		}
		.section3 {
			background: #ffffff;
			// opacity: 0.85;
			border-radius: 0px;
			// height: 154px;
			width: 100%;

			z-index: 2;
			padding: 0 23px 17px 23px;
			box-sizing: border-box;
			width: 100%;
            box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.05);

			.spn {
                display: none;
				// width: 250px;
				height: 28px;
				
				font-weight: 400;
				font-size: 30px;
				color: #753cbe;
				line-height: 40px;
			}
			.mod3 {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				position: relative;
                flex-wrap: wrap;
                .cus-form{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .sub-form{
                        width: 100%;
                        margin-top: 5px;
                    }
                    .el-form-item{
                        margin-bottom: 10px !important;
                        margin-right: initial !important;
                        height: 34px !important;

                        /deep/.el-form-item__content{
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
				.city-box {
					position: relative;
                    // margin-left: 10px;
                    width: 160px;
                    .input{
                        width: 100%;
                        height: 34px;
                        margin-top: -4px;
                        margin-left: 0px;
                        /deep/ .el-input{
                            
                            font-weight: 300;
                            font-size: 10px;
                            color: #3E3E3E;
                        }
                        /deep/ .el-input__inner::placeholder {
                            color: #3E3E3E;
                        }
                        /deep/ .el-input__suffix  {
                            
                            .el-icon-arrow-down{
                                font-size: 10px;
                            }
                            
                        }
                    }
					.city {
						/deep/ .el-input__inner {
							padding-left: 20px;
						}
                        /deep/ .el-input__inner:focus{
                            border-color: #753CBE !important;
                        }
					}
					.city-img {
						position: absolute;
						left: 5px;
						top: 18px;
						width: 10px;
						height: 9px;
                        object-fit: cover;
					}
				}

				.input {
                    // margin-left: 10px;
					width: 160px;
					height: 34px;
                    margin-bottom: 10px;
                    
                    font-weight: 300;
                    font-size: 10px;
                    color: #3E3E3E;
					/deep/ .el-input__inner {
						height: 34px;
                        padding-left: 20px;
					}
                    /deep/ .el-input__inner:focus{
                        border-color: #753CBE !important;
                    }
                    /deep/ .el-input__inner::placeholder {
                        color: #3E3E3E;
                    }
					/deep/ .el-input__prefix,
					.el-input__suffix {
						// line-height: 32px;
					}
					/deep/ .el-input__suffix {
						line-height: 34px;
					}
					.pic01 {
						width: 10px;
						height: auto;
                        object-fit: cover;
					}
					.m2 {
						
						font-weight: 300;
						font-size: 10px;
						color: #b1b1b1;
						line-height: 34px;
					}
				}

				.btn {
                    width: 100%;
                    height: 50px;
                    background: #f6900c;
                    border-radius: 2px;
					
					font-weight: 600;
					font-size: 14px;
					color: #ffffff;
                    margin: auto;
				}
			}
		}
	}
}
@media screen and (min-width: 900px) {
	.banner {
        // margin-top: 96px;
		// width: 1920px;
		// width: 100%;
		// height: 600px;
		// display: flex;
		position: relative;
		display: flex;
		justify-content: center;
		flex-flow: column;
		width: 100%;

		display: flex;
		align-items: center;
        .carousel-mob{
            display: none;
        }
		.carousel {
			width: 100%;
			height: 680px;
			position: relative;
            /deep/ .swiper-container {
                // height: 860px;
                height: 100%;
                z-index: initial !important;
            }
			.swiper {
				height: 100%;
                .img-mob {
                    display: none;
                }
                .img-pc{
                    width: 100%;
                    // height: 860px;
                    height: 100%;
                    display: block;
                    cursor: pointer;
                }
				
				/deep/ .swiper-wrapper {
					// height: 860px;
                    height: 100%;
				}
				/deep/ .swiper-pagination {
                    z-index: 9;
					display: flex;
					align-content: center;
					justify-content: start;
					box-sizing: border-box;
					width: 960px;
					margin-left: 50%;
					padding-left: 32px;
                    bottom: 0 !important;
					.swiper-pagination-customs {
						width: 130px;
						height: 1px;
						background: #dddddd;
						margin-right: 10px;
                        
					}
					.swiper-pagination-customs-activ {
						width: 130px;
						height: 1px;
						background: #1b1d22;
					}
				}
			}
			.info {
				position: absolute;
				right: 0;
				// bottom: 40px;
				bottom: 0px;
				width: 960px;
				height: 150px;
				background: rgba(255, 255, 255, 0.95);
				padding: 26px 180px 26px 32px;
				z-index: 5;
				box-sizing: border-box;
				.index {
					.num {
						font-family: DIN;
						font-weight: 300;
						font-size: 36px;
						color: #1b1d22;
					}
					.line {
						font-family: DIN;
						font-size: 30px;
						color: #000;
                        width: 1px;
                        background: #000;
                        height: 25px;
                        margin: 0 15px;
                        transform: rotate(12deg);
					}
				}
				.content {
                    width: 100%;
                    margin-top: 22px;
					.title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-right: 60px;
						width: 471px;
						height: 42px;
						
						font-weight: 400;
						font-size: 44px;
						color: #1b1d22;
						line-height: 40px;
                        flex: 1;
					}
					.right {
						font-size: 30px;
						// width: 27px;
						// height: 18px;
						color: #753cbe;
					}
				}
			}
		}
		

		/deep/.el-carousel__container {
			height: 860px;
		}

		.section3 {
			background: #ffffff;
			// opacity: 0.85;
			border-radius: 0px;
			// height: 160px;
			width: 100%;

			z-index: 2;
			padding: 13px 180px 23px 180px;
			box-sizing: border-box;
			width: 100%;
            box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.05);
			.spn {
				// width: 250px;
				// height: 28px;
				
				font-weight: 400;
				font-size: 24px;
				color: #753cbe;
				line-height: 24px;
			}
			.mod3 {
				width: 100%;
				height: 70px;
				margin-top: 18px;
				display: flex;
				justify-content: space-between;
				position: relative;
                .cus-form{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    .el-form-item{
                        margin-right: initial !important;
                    }
                }
				.city-box {
					position: relative;
					.city {
						/deep/ .el-input__inner {
							padding-left: 30px;
						}
                        /deep/ .el-input__inner:focus{
                            border-color: #753CBE !important;
                        }
					}
					.city-img {
						position: absolute;
						left: 5px;
						top: 23px;
						width: 20px;
						// height: 17px;
                        object-fit: cover;
					}
				}

				.input {
					width: 290px;
					height: 70px;
					/deep/ .el-input__inner {
						height: 70px;
                        font-size: 20px;
					}
                    /deep/ .el-input__inner:focus{
                            border-color: #753CBE !important;
                        }
					/deep/ .el-input__prefix,
					.el-input__suffix {
						line-height: 75px;
					}
					/deep/ .el-input__suffix {
						line-height: 70px;
					}
					.pic01 {
						width: 20px;
						// height: 19px;
                        object-fit: cover;
					}
                    /deep/ .el-icon-arrow-down{
                        font-size: 24px;
                        line-height: 70px;
                    }
					.m2 {
						
						font-weight: 300;
						font-size: 22px;
						color: #b1b1b1;
						line-height: 40px;
					}
				}

				.btn {
					width: 290px;
					height: 70px;
					background: #f6900c;
					border-radius: 4px;
					cursor: pointer;
					font-weight: 600;
					font-size: 28px;
					color: #ffffff;
				}
			}
		}
	}
}
</style>
